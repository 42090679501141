import makeRequest, { makeParams } from "./makeRequest";
import makeUploadRequest from "./makeUploadRequest";
import urls from "./urls";

export default class CenterService {
  static async getAllCenters() {
    return await makeRequest(urls.centers.getAllCenters, "GET");
  }
  static async getEmployeeById(centerId: any) {
    return await makeRequest(
      urls.centers.getEmployeeById + "/" + centerId,
      "GET"
    );
  }
  static async getCenterlabs(centerId: any) {
    return await makeRequest(
      urls.centers.getCenterlabs + "/" + centerId,
      "GET"
    );
  }

  static async editCenterEmployee(centerId: string, payload: any) {
    return await makeRequest(
      urls.centers.editCenterEmployee + "/" + centerId,
      "PUT",
      payload
    );
  }
  static async getCenterDetailsByObjectId(centerId: string) {
    return await makeRequest(
      urls.centers.getCenterDetailsByObjectId + "/" + centerId,
      "GET"
    );
  }
  static async getCenterBuildingPlan(centerId: string) {
    return await makeRequest(
      urls.centers.getCenterBuilding + "/" + centerId,
      "GET"
    );
  }
  static async addLocation(centerId: string, updateObj: any) {
    return await makeRequest(
      urls.centers.addLocation + "/" + centerId,
      "POST",
      updateObj
    );
  }
  static async getCenterLocation(centerId: string) {
    return await makeRequest(
      urls.centers.getCenterLocation + "/" + centerId,
      "GET"
    );
  }
  static async updateCenterBasicDetails(
    centerId: string,
    center: any,
    pincode: any
  ) {
    return await makeRequest(
      urls.centers.updateCenterBasicDetails + "/" + centerId,
      "PUT",
      { center, pincode }
    );
  }
  static async updateCenterFacilities(centerId: string, payload: any) {
    return await makeRequest(
      urls.centers.updateCenterFacilities + "/" + centerId,
      "PUT",
      payload
    );
  }
  static async updateStatus(centerId: string, payload: any) {
    return await makeRequest(
      urls.centers.updateStatus + "/" + centerId,
      "PUT",
      payload
    );
  }
  static async createCenterEmployee(payload: any, centerId: string) {
    return await makeRequest(
      urls.centers.createCenterEmployee + "/" + centerId,
      "POST",
      payload
    );
  }
  static async getCentersEmployeeData(centerId: any) {
    return await makeRequest(
      urls.centers.getCentersEmployeeData + "/" + centerId,
      "GET"
    );
  }
  static async insertBuilding(
    centerId: any,
    { name, location, otherInfo }: any
  ) {
    return await makeRequest(
      urls.centers.insertBuildings + "/" + centerId,
      "POST",
      {
        name,
        location,
        otherInfo,
      }
    );
  }

  static async addFloor(
    centerId: string,
    buildingId: string,
    floorObject: any
  ) {
    return await makeRequest(
      urls.centers.addFloorToBuilding + "/" + centerId + "/" + buildingId,
      "POST",
      floorObject
    );
  }
  static async removeFloorFromBuilding(
    centerId: string,
    buildingId: string,
    floorId: string
  ) {
    return await makeRequest(
      urls.centers.removeFloorFromBuilding +
      "/" +
      centerId +
      "/" +
      buildingId +
      "/" +
      floorId,
      "DELETE"
    );
  }
  static async removeBuilding(centerId: string, buildingId: string) {
    return await makeRequest(
      urls.centers.removeBuilding + "/" + centerId + "/" + buildingId,
      "DELETE"
    );
  }
  static async createBooking(payload: any) {
    return await makeRequest(urls.centers.createBooking, "POST", payload);
  }

  static async createNewLab(payload: any) {
    return await makeRequest(urls.centers.createNewLab, "POST", payload);
  }

  static async getCenterLabs(centerId: any) {
    return await makeRequest(
      urls.centers.getCenterLabs + "/" + centerId,
      "GET"
    );
  }

  static async getApprovalsByCenterId(centerId: any) {
    return await makeRequest(
      urls.centers.getApprovalsByCenterId + "/" + centerId,
      "GET"
    );
  }

  static async getCenterPercentage(centerId: any) {
    return await makeRequest(
      urls.centers.getCenterPercentage + "/" + centerId,
      "GET"
    );
  }

  static async uploadManPowerCSV(formData: any, centerId: string) {
    return await makeUploadRequest(
      urls.centers.uploadManPowerCSV + "/" + centerId,
      "POST",
      formData
    );
  }

  static async uploadZip(formData: any, centerId: string) {
    return await makeUploadRequest(
      urls.centers.uploadZip + "/" + centerId,
      "POST",
      formData
    );
  }

  static async getAllEmployeeByCenter(centerId: string) {
    return await makeRequest(
      urls.centers.getAllEmployeeByCenter + "/" + centerId,
      "GET"
    );
  }
  static async removeLab(centerId: string) {
    return await makeRequest(urls.centers.removeLab + "/" + centerId, "DELETE");
  }

  static async getEmployeeByCenterById(centerId: string, employeeId: string) {
    return await makeRequest(
      urls.centers.getEmployeeByCenterById + "/" + centerId + "/" + employeeId,
      "GET"
    );
  }

  // static async createApprovalsByCenterId(centerId: any, payload: any) {
  //     return await makeRequest(urls.centers.createApprovals + "/" + centerId, "POST", {
  //         visited: payload.visited,
  //         nodesAvailable: payload.approvalValue.nodesAvailable,
  //         nodesApproved: payload.approvalValue.nodesApproved,
  //         numberofLabsApproved: payload.approvalValue.numberOfLabsApproved,
  //         approvedOn: payload.approvedOn,
  //         approvalValidUpto: payload.approvalValidUpto
  //     });
  // }

  static async createApprovalsByCenterId(centerId: any, payload: any) {
    return await makeRequest(
      urls.centers.createApprovals + "/" + centerId,
      "POST",
      payload
    );
  }

  static async createCenterCoordinates(centerId: any, payload: any) {
    return await makeRequest(
      urls.centers.createCenterCoordinates + "/" + centerId,
      "POST",
      payload
    );
  }

  static async getCenterCoordinates(centerId: any) {
    return await makeRequest(
      urls.centers.getCenterCoordinates + "/" + centerId,
      "GET"
    );
  }

  static async getComputerHistory(mac: any) {
    return await makeRequest(urls.labs.getMacHistory + mac, "GET");
  }

  static async uploadComputersCSV(formData: any, centerId: any, labId: any) {
    return await makeUploadRequest(
      urls.labs.addComputers + "/" + centerId + "/" + labId,
      "POST",
      formData
    );
  }

  static async searchCenter(payload: any, param?: any) {
    const params = makeParams([
      {
        index: "search",
        value: param,
      },
    ]);

    return await makeRequest(
      urls.centers.searchCenter + params,
      "POST",
      payload
    );
  }

  static async updateCenterData(centerId: string, payload: any) {
    return await makeRequest(
      urls.centers.updateCenterData + "/" + centerId,
      "PUT",
      payload
    );
  }

  static async getCenterDetails(centerId: string) {
    return await makeRequest(
      urls.centers.getCenterDetails + "/" + centerId,
      "GET"
    );
  }

  static async resetPassword(center_id: any) {
    return await makeRequest(
      urls.centers.resetPassword + "/" + center_id,
      "PUT"
    );
  }

  static async deleteEmployee(employee_id: any) {
    return await makeRequest(
      urls.centers.deleteEmployee + "/" + employee_id,
      "DELETE"
    );
  }

  static async editEmployee(employee_id: any, payload: any) {
    return await makeRequest(
      urls.centers.editEmpoyee + "/" + employee_id,
      "PUT",
      payload
    );
  }

  static async getEmployeeType() {
    return await makeRequest(urls.centers.employeeType, "GET");
  }

  static async downloadCenterCsv(payload: any) {
    return await makeRequest(urls.centers.downloadCenterList, "POST", payload);
  }

  static async editApprovals(centerId: any, approvalId: any, payload: any) {
    return await makeUploadRequest(urls.centers.updateApprovalInfo + "/" + centerId + "/" + approvalId, "PUT", payload)
  }

  static async getRoleWiseData(centerId: any) {
    return await makeRequest(urls.centers.employeeInfo + "/" + centerId, "GET")
  }

  static async getBuildingInfo(centerId: any) {
    return await makeRequest(urls.centers.getBuildingInfo + "/" + centerId, "GET")
  }
}
