import React, { useEffect, useState } from "react";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import XcnButton from "../../../../components/XcnButton/XcnButton";
import CenterService from "../../../../services/center.service";
import CenterContact from "./CenterContact";
import CenterFacilities from "./CenterFacilities";
import CenterStaticInfo from "./CenterStaticInfo";
import Location from "./Location";
import CenterMap from "./CenterMap.index";

interface IUpdateCenterDetails {
  centerContact: string;
  centerEmail: string;
  address: string;
  name: string;
  gstNumber: string;
  spocName: string;
  nameOfDivisionHq: string,
  venueItPersonName: string,
  venueITPersonContactNo: string,
  venueItEmailId: string,
}

interface IUpdatePincode {
  state: any;
  district: any;
  pincode: string;
}

interface IUpdateCenterFacilities {
  drinkingWater: boolean;
  facilitiesForPhysicallyHandicapped: boolean;
  luggageArea: boolean;
  waitingArea: boolean;
  receptionArea: boolean;
  washrooms: boolean;
  googleCoordinate: string;
  venueSchedulingCount: string;
  venueSchedulingCountWithSD: string;
  cctv: boolean;
  pwdVenue: boolean;
  labsOnGroundFloor: boolean;
  lift: boolean;
  wheelChair: boolean;
  venuePersonCapacity: string;
}
export default function CenterDetail() {
  const params: any = useParams();

  const [currentPosition, setCurrentPosition] = useState<any>({});
  const [coordinatesData, setCoordinatesData] = useState<any>();

  const [centerDetails, setCenterDetails] = useState<any>({});
  const [updateCenterDetails, setUpdateCenterDetails] =
    useState<IUpdateCenterDetails>({
      centerContact: "",
      centerEmail: "",
      address: "",
      name: "",
      gstNumber: "",
      spocName: "",
      nameOfDivisionHq: "",
      venueItPersonName: "",
      venueITPersonContactNo: "",
      venueItEmailId: "",
    });
  const [updatePincode, setUpdatePincode] = useState<IUpdatePincode>({
    state: "",
    district: "",
    pincode: "",
  });

  const [updateCenterFacilities, setUpdateCenterFacilities] =
    useState<IUpdateCenterFacilities>({
      drinkingWater: false,
      facilitiesForPhysicallyHandicapped: false,
      luggageArea: false,
      waitingArea: false,
      receptionArea: false,
      washrooms: false,
      googleCoordinate: "",
      venueSchedulingCount: "",
      venueSchedulingCountWithSD: "",
      cctv: true,
      pwdVenue: true,
      labsOnGroundFloor: false,
      lift: false,
      wheelChair: false,
      venuePersonCapacity: "",
    });

  const [centerPercentage, setCenterPercentage] = useState<any>(0);

  const getCenterPercentage = async () => {
    await CenterService.getCenterPercentage(params.centerId).then((res) => {
      if (res.status === 200) {
        const obj = res.data;
        setCenterPercentage(obj);
      }

      // console.log(centerPercentage.completionPercent);
      // setCenterPercentage(obj);
    });
  };

  const getCenterDetails = async () => {
    await CenterService.getCenterDetailsByObjectId(params.centerId).then(
      (res) => {
        const obj = res.data;

        // console.log("center details: ", obj)
        setCenterDetails(obj);
        setUpdateCenterDetails({
          centerContact: obj.center.centerContact,
          centerEmail: obj.center.centerEmail,
          address: obj.center.address,
          name: obj.center.name,
          gstNumber: obj.center.gstNumber,
          spocName: obj.center.spocName,
          nameOfDivisionHq: obj.center.nameOfDivisionHq,
          venueItPersonName: obj.center.venueItPersonName,
          venueITPersonContactNo: obj.center.venueITPersonContactNo,
          venueItEmailId: obj.center.venueItEmailId,
        });
        setUpdatePincode({
          state: obj.pincode.state,
          district: obj.pincode.district,
          pincode: obj.pincode.pincode,
        });
        setUpdateCenterFacilities({
          drinkingWater: obj.center.drinkingWater,
          facilitiesForPhysicallyHandicapped:
            obj.center.facilitiesForPhysicallyHandicapped,
          luggageArea: obj.center.luggageArea,
          waitingArea: obj.center.waitingArea,
          receptionArea: obj.center.receptionArea,
          washrooms: obj.center.washrooms,
          googleCoordinate: obj.center.googleCoordinate,
          venueSchedulingCount: obj.center.venueSchedulingCount,
          venueSchedulingCountWithSD: obj.center.venueSchedulingCountWithSD,
          cctv: obj.center.cctv,
          pwdVenue: obj.center.pwdVenue,
          labsOnGroundFloor: obj.center.labsOnGroundFloor,
          lift: obj.center.lift,
          wheelChair: obj.center.wheelChair,
          venuePersonCapacity: obj.center.venuePersonCapacity,
        });
      }
    );
  };

  const handleCenterContactDetailsChange = (e: any) => {
    const centerDetailData: any = {
      ...updateCenterDetails,
      [e.target.name]: e.target.value,
    };
    setUpdateCenterDetails(centerDetailData);
  };

  const handlePincodeDetails = (e: any) => {
    setUpdatePincode({ ...updatePincode, [e.target.name]: e.target.value });
  };

  const handleCenterFacilities = (e: any) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;

    const centerFacilitiesData: any = {
      ...updateCenterFacilities,
      [e.target.name]: value,
    };
    setUpdateCenterFacilities(centerFacilitiesData);
  };

  console.log("center id: ", params.centerId);

  const getCenterCoordinates = async () => {
    await CenterService.getCenterCoordinates(params.centerId).then((res) => {
      if (res.status === 200) {
        setCoordinatesData(res.data.coordinates);
      }
    });
  };

  useEffect(() => {
    getCenterCoordinates();
  }, [currentPosition]);

  useEffect(() => {
    getCenterDetails();
    getCenterPercentage();
  }, []);

  return (
    <>
      <Container className="xcd-container-large xcn-conatiner">
        <Row>
          <Col md={7}>
            <CenterContact
              onChange={handleCenterContactDetailsChange}
              updateCenterDetails={updateCenterDetails}
              updateCenterFacilities={updateCenterFacilities}
              onUpdatingFacilities={handleCenterFacilities}
              handlePincodeDetails={handlePincodeDetails}
              pincode={updatePincode}
              reload={getCenterDetails}
              coordinatesData={coordinatesData}
            />
          </Col>

          <Col md={5}>
            <Row>
              <Col>
                <CenterStaticInfo
                  centerDetails={centerDetails}
                  centerPercentage={centerPercentage}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <CenterFacilities
                  onChange={handleCenterFacilities}
                  updateCenterFacilities={updateCenterFacilities}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CenterMap
                  centerId={params.centerId}
                  setCurrentPosition={setCurrentPosition}
                  currentPosition={currentPosition}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col md={4}></Col>
        </Row>
      </Container>
    </>
  );
}
