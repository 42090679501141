const urls = {
  corporate: {
    getAllCorps: "/adminuser/corporate/getAllCorps",
    getcorpcenters: "/adminuser/corporate/getcorpcenters",
    addCenter: "/adminuser/corporate/addcenter",
    getCorpDetails: "/adminuser/corporate/getCorpDetails",
    searchCorps: "/adminuser/corporate/search",
    removeCorpUser: "/adminuser/corporate/removeCorpUser",
    removeCorps: "/adminuser/corporate/removeCenter",
    searchCenter: "/adminuser/corporate/search",
    resetPassword: "/adminuser/corporate/resetPassword",
  },
  public: {
    addCorpUser: "/public/addCorpUser",
  },
  common: {
    getPincodeInfo: "/protected/getPincodeInfo",
    getStates: "/protected/getStates",
    getCityByName: "/protected/getDistrictsByState",
    getAllCorps: "/protected/getAllCorpsId",
  },
  labs: {
    getMacHistory: "/center/labs/getMacHistory?mac=",
    addComputers: "/center/labs/addComputers",
  },
  centers: {
    getAllCenters: "/adminuser/centers/getAllcenters",
    getCenterlabs: "/adminuser/centers/getCenterlabs",
    getCenterDetailsByObjectId: "/center/info/getCenterByObjectId",
    getCenterBuilding: "/adminuser/centers/getBuildingsByCenterId",
    getCenterById: "/adminuser/centers/getCenterById",
    getCenterLocation: "/center/info/getCenterLocation",
    addLocation: "/center/info/addLocation",
    updateCenterBasicDetails: "/adminuser/centers/updateCenterBasicDetails",
    updateCenterFacilities: "/adminuser/centers/updateCenterFacilities",
    createCenterEmployee: "/adminuser/centers/createCenterEmployee",
    getCentersEmployeeData: "/adminuser/centers/getCenterEmployeeData",
    insertBuildings: "/adminuser/centers/insertBuildings",
    addFloorToBuilding: "/adminuser/centers/addFloorToBuilding",
    removeFloorFromBuilding: "/adminuser/centers/removeFloorFromBuilding",
    removeBuilding: "/adminuser/centers/removeBuilding",
    createBooking: "/adminuser/centers/createBooking",
    getCenterLabs: "/adminuser/centers/getCenterlabs",
    getApprovalsByCenterId: "/center/approval/get",
    createApprovals: "/center/approval/create",
    createNewLab: "/adminuser/centers/createNewLab",
    getAllEmployeeByCenter: "/center/employee/getAll",
    getEmployeeByCenterById: "/center/employee/getBy_id",
    uploadManPowerCSV: "/center/employee/uploadManPowerCSV",
    uploadZip: "/center/employee/uploadEmployeeZip",
    searchCenter: "/adminuser/centers/search-filter",
    removeLab: "/adminuser/centers/removeLabs",
    editCenterEmployee: "/center/employee/update",
    getEmployeeById: "/center/employee/get",
    updateStatus: "/adminuser/centers/toggleStatus",
    updateCenterData: "/adminuser/centers/updateCenterData",
    getCenterDetails: "/adminuser/centers/getCenterDetails",
    resetPassword: "/adminuser/centers/reset-password",
    deleteEmployee: "/adminuser/centers/deleteCenterEmployeeData",
    editEmpoyee: "/adminuser/centers/updateCenterEmployeeData",
    employeeType: "/adminuser/centers/employeeTypes ",
    employeeInfo: "/adminuser/centers/employee/info",
    downloadCenterList: "/adminuser/centers/download-csv",
    getCenterPercentage: "/adminuser/centers/percent",
    createCenterCoordinates: "/adminuser/centers/createCenterCoordinates",
    getCenterCoordinates: "/adminuser/centers/getCenterCoordinates",
    updateApprovalInfo: "/center/approval/edit",
    getBuildingInfo: "/adminuser/centers/system/count",
  },
  stats: {
    getApiCountForGraph: "/api/v1/stats/getApiCountForGraph",
    getCorporatesForGraph: "/api/v1/stats/getCorporatesForGraph",
  },
  admin: {
    createApiKey: "/adminuser/extapi/apikey",
    getAllApiKeys: "/adminuser/extapi/getallapikeys",
    toggleApiKeyStatus: "/adminuser/extapi/apikey/toggle",
    deleteKey: "/adminuser/extapi/apikey",
  },
  exam: {
    createExam: "/exam/createExam",
    getAllExams: "/exam/getAllExams",
    getExamShifts: "/exam/getExamShifts",
    getExamDetails: "/exam/getExamDetails",
    addShift: "/exam/addShift",
    deleteShift: "/exam/deleteShift",
    uploadUserData: "/exam/uploadUserData",
    getAllUploadedUserData: "/exam/getAllUploadedUserData",
  },
  jobs: {
    getSheetFields: "/exam/getSheetFields",
    createFieldMapping: "/exam/createFieldMapping",
    getSheetDistrict: "/exam/getSheetDistrict",
  },
  mappings: {
    getFieldMappingForJobId: "/adminuser/mapping/getFieldMappingForJobId",
    autoSelectCentersForDist: "/adminuser/mapping/autoSelectCentersForDist",
    updateCityMapping: "/adminuser/mapping/updateCityMapping",
    getDistCapacityByJobId: "/adminuser/mapping/getDistCapacityByJobId",
    getAvailableCentersInDistByJobId:
      "/adminuser/mapping/getAvailableCentersInDistByJobId",
    saveCenterSelectionDistrictShiftWise:
      "/adminuser/mapping/saveCenterSelectionDistrictShiftWise",
    getRequiredSeatsByJobId: "/adminuser/mapping/getRequiredSeatsByJobId",
    getCenterData: "/adminuser/mapping/getCenterData",
    isAllDistrictMappedToCenter:
      "/adminuser/mapping/isAllDistrictMappedToCenter",
  },
  adminLogin: "/public/adminlogin",
  getAllBookingsForCenter: "/adminuser/centers/getAllBookingsForCenter/",
  getCenterLabs: "/adminuser/centers/getCenterlabs/",
  getApiCountForGraph: "/stats/getApiCountForGraph",
  getEnum: "/public/getEnums",
};

export default urls;
