import makeRequest from "./makeRequest";
import urls from "./urls";

export default class AuthService{
    static async adminLogin(username: string, password: string){
        return await makeRequest(urls.adminLogin, "POST", {
            email: username,
            password: password
        });
    }

    static async getEnum(){
        return await makeRequest(urls.getEnum, "GET");
    }
}

