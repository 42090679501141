import { Card, Col, Container, Form, Row } from "react-bootstrap";
import XcnButton from "../../../../components/XcnButton/XcnButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import InputBox from "../../../../components/Input/InputBox";
import CenterService from "../../../../services/center.service";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

interface IOtherInfo {
    isThisVenueSuitableForPhysicallyChallangedCandidates: boolean,
    isThereAServerRoomAvailable: boolean,
    isThereServerRoomInACEnvironment: boolean,
    doesTheServerRoomHasUpsPowerPointsNetworkPoints: boolean,
    areTheTestAreaAirConditioned: boolean,
    isTheAirConditionControllable: boolean,
    ifNoAreThereEnoughFansAvailableInsideTheLab: boolean,
    areTherePartitionsAvailableBetweenSystems: boolean,
    isThereAPrinterAvailableInTheCentre: boolean,
    MentionPrinterMakeAndModel: string,
    howManyEntryPointsDoTheExaminationVenueHas: number,
    howMayExitPointsDoTheExaminationVenueHas: number,
    drinkingWaterWillBeProvidedInAllTheFloorsLabsOfTheExaminationVenue: boolean,
    areTheTestAreasTestRoomWaitingAreaAndTheRegistrationAreaWellLit: boolean,
    willTheLabsBeNoiseFreeAndSoundProofDuringTheTest: boolean,
    doesTheVenueHasParkingFacilityAndWillTheCandidatesBeAllowedToParkInsideTheVenue: boolean,
    ifYesHowManyCarsBikesCanBeParked: number,
    isTheVenueEasilyAccessibleToPublic: boolean,
    isThereProperRoadAccessToTheVenue: boolean,
    isItLocatedInASafeAreaWhereThereIsNoObviousThreatToPersonalSafetyAndGeneralPublic: boolean,
    isItSafeToAccessTheVenueDuringEarlyAndLateHours: boolean,
    isAdequateHygieneMaintainedAcrossTheVenue: boolean,
    areThereSeparateToiletsForMaleAndFemale: boolean,
    isThereALiftAvailableForTheCandidates: boolean,
}

export default function OtherInfo() {
    const params: any = useParams()
    const [editEnabled, setEditEnabled] = useState<boolean>(false);
    const [otherInfoData, setOtherInfoData] = useState<IOtherInfo>();


    const handleChange = (e: any) => {
        let newData: any;
        if (e.target.type === "checkbox") {
            newData = {
                ...otherInfoData,
                [e.target.name]: e.target.checked,
            };
        } else {
            newData = {
                ...otherInfoData,
                [e.target.name]: e.target.value,
            };
        }
        setOtherInfoData(newData);
    };


    const getCenterDetails = async () => {
        await CenterService.getCenterDetails(params.centerId)
            .then((res) => {
                setOtherInfoData(res.data.otherInfo
                );
            })
            .catch((error) => {
                toast.error(
                    error.response.status +
                    " Something went wrong " +
                    `${error.response.data.message}`
                );
            });
    };

    function removeEmpty(obj: any) {
        return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ""));
    }

    const updateCenterData = async () => {
        await CenterService.updateCenterData(params.centerId, {
            otherInfo: removeEmpty(otherInfoData),
        })
            .then((res) => {
                if (res.status === 200) {
                    toast.success("Data updated successfully.");
                    getCenterDetails();
                    setEditEnabled(false);
                }
            })
            .catch((error) => {
                toast.error(
                    error.response.status +
                    " Something went wrong " +
                    `${error.response.data.message}`
                );
            });
    };

    useEffect(() => {
        getCenterDetails();
    }, []);
    return (
        <Container>
            <Card className="xcn-card mt-3">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="text-secondary fw-bold">Other Information</h5>
                    <div>
                        {editEnabled ? (
                            <XcnButton
                                text={
                                    <>
                                        <FontAwesomeIcon icon={faSave} className="me-2" />
                                        Save
                                    </>
                                }
                                variant="success"
                                onClick={updateCenterData}
                            />
                        ) : (
                            <XcnButton
                                text={
                                    <>
                                        <FontAwesomeIcon icon={faEdit} className="me-2" />
                                        Edit
                                    </>
                                }
                                variant="warning"
                                onClick={() => setEditEnabled(true)}
                            />
                        )}
                    </div>
                </div>
                <Row className="mt-3">
                    <Col md={6}>
                        <InputBox
                            type="number"
                            name="howManyEntryPointsDoTheExaminationVenueHas"
                            label="How many entry points do the Examination Venue has?"
                            defaultValue={otherInfoData?.howManyEntryPointsDoTheExaminationVenueHas}
                            disabled={!editEnabled}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col md={6}>
                        <InputBox
                            type="number"
                            name="howMayExitPointsDoTheExaminationVenueHas"
                            label="How many exit point do the Examination Venue has?"
                            defaultValue={otherInfoData?.howMayExitPointsDoTheExaminationVenueHas}
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={6}>
                        <InputBox
                            type="number"
                            name="ifYesHowManyCarsBikesCanBeParked"
                            label="How many cars annd bikes can be parked"
                            defaultValue={otherInfoData?.ifYesHowManyCarsBikesCanBeParked}
                            disabled={!editEnabled}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col md={6}>
                        <InputBox
                            type="text"
                            name="MentionPrinterMakeAndModel"
                            label="Mention Printer Make and Model"
                            defaultValue={otherInfoData?.MentionPrinterMakeAndModel}
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </Col>
                </Row>
                <div className="d-flex justify-content-start align-items-center flex-wrap gap-4 p-4">
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="isThisVenueSuitableForPhysicallyChallangedCandidates"
                            label="Is this venue suitable for physically challenged candidates"
                            checked={otherInfoData?.isThisVenueSuitableForPhysicallyChallangedCandidates}
                            name="isThisVenueSuitableForPhysicallyChallangedCandidates"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="isThereAServerRoomAvailable"
                            label="Is there any server room available"
                            checked={otherInfoData?.isThereAServerRoomAvailable}
                            name="isThereAServerRoomAvailable"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="isThereServerRoomInACEnvironment"
                            label="Is there server room is AC Enviroment"
                            checked={otherInfoData?.isThereServerRoomInACEnvironment}
                            name="isThereServerRoomInACEnvironment"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="doesTheServerRoomHasUpsPowerPointsNetworkPoints"
                            label="Does the server room has UPS power points network points"
                            checked={otherInfoData?.doesTheServerRoomHasUpsPowerPointsNetworkPoints}
                            name="doesTheServerRoomHasUpsPowerPointsNetworkPoints"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="areTheTestAreaAirConditioned"
                            label="Are the test area Air Conditioned"
                            checked={otherInfoData?.areTheTestAreaAirConditioned}
                            name="areTheTestAreaAirConditioned"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>

                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="willTheLabsBeNoiseFreeAndSoundProofDuringTheTest"
                            label="Will the labs be noise-free & soundproof during the test?"
                            checked={otherInfoData?.willTheLabsBeNoiseFreeAndSoundProofDuringTheTest}
                            name="willTheLabsBeNoiseFreeAndSoundProofDuringTheTest"
                            disabled={!editEnabled}
                            onChange={handleChange}
                        />
                    </span>

                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="isTheAirConditionControllable"
                            label="Is the AC controllable"
                            checked={otherInfoData?.isTheAirConditionControllable}
                            name="isTheAirConditionControllable"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="ifNoAreThereEnoughFansAvailableInsideTheLab"
                            label="If no are there enough fans available inside the lab"
                            checked={otherInfoData?.ifNoAreThereEnoughFansAvailableInsideTheLab}
                            name="ifNoAreThereEnoughFansAvailableInsideTheLab"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="areTherePartitionsAvailableBetweenSystems"
                            label="Are there partition available between systems"
                            checked={otherInfoData?.areTherePartitionsAvailableBetweenSystems}
                            name="areTherePartitionsAvailableBetweenSystems"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="isThereAPrinterAvailableInTheCentre"
                            label="Is there are Printer available in the center"
                            checked={otherInfoData?.isThereAPrinterAvailableInTheCentre}
                            name="isThereAPrinterAvailableInTheCentre"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="drinkingWaterWillBeProvidedInAllTheFloorsLabsOfTheExaminationVenue"
                            label="Drinking water will be provided in all the floors labs of the examination vanue"
                            checked={otherInfoData?.drinkingWaterWillBeProvidedInAllTheFloorsLabsOfTheExaminationVenue}
                            name="drinkingWaterWillBeProvidedInAllTheFloorsLabsOfTheExaminationVenue"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="areTheTestAreasTestRoomWaitingAreaAndTheRegistrationAreaWellLit"
                            label="Are the test areas and test room waiting area and the registration area well lit"
                            checked={otherInfoData?.areTheTestAreasTestRoomWaitingAreaAndTheRegistrationAreaWellLit}
                            name="areTheTestAreasTestRoomWaitingAreaAndTheRegistrationAreaWellLit"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="doesTheVenueHasParkingFacilityAndWillTheCandidatesBeAllowedToParkInsideTheVenue"
                            label="Does the venue had parking facility and will the candidates be allowed to park inside the venue"
                            checked={otherInfoData?.doesTheVenueHasParkingFacilityAndWillTheCandidatesBeAllowedToParkInsideTheVenue}
                            name="doesTheVenueHasParkingFacilityAndWillTheCandidatesBeAllowedToParkInsideTheVenue"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="isTheVenueEasilyAccessibleToPublic"
                            label="Is the venue easily accessible to public"
                            checked={otherInfoData?.isTheVenueEasilyAccessibleToPublic}
                            name="isTheVenueEasilyAccessibleToPublic"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="isThereProperRoadAccessToTheVenue"
                            label="Is there proper road access to the venue "
                            checked={otherInfoData?.isThereProperRoadAccessToTheVenue}
                            name="isThereProperRoadAccessToTheVenue"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="isItLocatedInASafeAreaWhereThereIsNoObviousThreatToPersonalSafetyAndGeneralPublic"
                            label="Is it located in a safe area where there is no obvious threat to personal safety and general public"
                            checked={otherInfoData?.isItLocatedInASafeAreaWhereThereIsNoObviousThreatToPersonalSafetyAndGeneralPublic}
                            name="isItLocatedInASafeAreaWhereThereIsNoObviousThreatToPersonalSafetyAndGeneralPublic"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="isItSafeToAccessTheVenueDuringEarlyAndLateHours"
                            label="Is it safe to acces the venue during early and late hours "
                            checked={otherInfoData?.isItSafeToAccessTheVenueDuringEarlyAndLateHours}
                            name="isItSafeToAccessTheVenueDuringEarlyAndLateHours"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="isAdequateHygieneMaintainedAcrossTheVenue"
                            label="Is Adequate hygiene maintained across the venue"
                            checked={otherInfoData?.isAdequateHygieneMaintainedAcrossTheVenue}
                            name="isAdequateHygieneMaintainedAcrossTheVenue"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="areThereSeparateToiletsForMaleAndFemale"
                            label="Are there seperate toilets for Male and Female "
                            checked={otherInfoData?.areThereSeparateToiletsForMaleAndFemale}
                            name="areThereSeparateToiletsForMaleAndFemale"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                    <span>
                        <Form.Check
                            inline
                            type="switch"
                            id="isThereALiftAvailableForTheCandidates"
                            label="Is there a lift available for the candidates "
                            checked={otherInfoData?.isThereALiftAvailableForTheCandidates}
                            name="isThereALiftAvailableForTheCandidates"
                            disabled={!editEnabled}
                            onChange={handleChange}

                        />
                    </span>
                </div>
            </Card>
        </Container>
    )
}