import { faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, FloatingLabel, Form, Image, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import InputBox from "../../../../components/Input/InputBox";
import XcnButton from "../../../../components/XcnButton/XcnButton";
import CenterService from "../../../../services/center.service";
import MyUploader from "../../../../components/Dropzone/dropzone";
import { UploadService } from "../../../../services/upload.service";
import Select from "react-select"
import AuthService from "../../../../services/auth.service";
interface ISurveillance {
  numberOfCamera: any;
  allLabCovered: boolean;
  dvrBrand: string;
  typeOfCamera: string;
  backupCapacity: string;


  //added

  totalNoOfSystem: number,
  dvrNvrAvailability: boolean,
  noOfWorkingCamerasAvailable: number,
  sizeOfTheDvrHardDisk: string,
  perCameraRecordingSizePerHour: string,
  howManyDaysRecordingCanStoreInDvrHdd: number,
  dvrRecordingWillBeHandedOverOnDayToDayBasic: boolean,
  howTheCenterWillGiveTheRecordingOnDailyBasis: string,
  analogCam: number,
  ipFHdGT2MpCamera: number,
  ipHdLT2MpCamera: number,
  ipType: string,
  ipAddress: string,

  //new entyr

  dvr: string,
  cctvPanel: string,
  nvr: string,
  internalBroadband: string

}


export default function Surveillance() {
  const params: any = useParams();

  const [editEnabled, setEditEnabled] = useState<boolean>(false);
  const [surveillanceDetails, setSurveillanceDetails] =
    useState<ISurveillance | any>();
  const [enumData, setEnumData] = useState<any>({});

  const handleChange = (e: any) => {
    let newData: any;
    if (e.target.type === "checkbox") {
      newData = {
        ...surveillanceDetails,
        [e.target.name]: e.target.checked,
      };
    } else {
      newData = {
        ...surveillanceDetails,
        [e.target.name]: e.target.value,
      };
    }
    setSurveillanceDetails(newData);
  };


  const getEnums = async () => {
    await AuthService.getEnum().then((res) => {
      if (res.status == 200) {
        setEnumData(res.data);
      }
    }).catch(e => {
      console.log(e)
      toast.error("Unable to fetch enums")
    })
  }

  const fileSelectedHandler = async (e: any, name: string) => {
    const fd = new FormData();
    // let arr=[];
    for (let i of e) {
      fd.append("upload", i);
    }
    await UploadService.uploadFileToS3(fd).then((res: any) => {
      if (res.status === 200) {
        let imageData: any = res.data && res.data[0];
        setSurveillanceDetails({ ...surveillanceDetails, [name]: imageData })
        toast.success(`File(s) uploaded`);
      } else {
        toast.error("Something went wrong");
      }
    });
  };

  function removeEmpty(obj: any) {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== ""));
  }

  const getCenterDetails = async () => {
    await CenterService.getCenterDetails(params.centerId)
      .then((res) => {
        setSurveillanceDetails(res.data.cctvInfo);
      });
  };

  useEffect(() => {
    getCenterDetails();
    getEnums();
  }, []);

  useEffect(() => {
  }, [surveillanceDetails]);

  const updateCenterData = async () => {
    await CenterService.updateCenterData(params.centerId, {
      cctvInfo: removeEmpty(surveillanceDetails),
    })
      .then((res) => {
        if (res.status === 200) {
          toast.success("Data updated successfully.");
          setEditEnabled(false);
          getCenterDetails();
        }
      })
      .catch((error) => {
        toast.error(
          `${error.response.data.message}`
        );
      });
  };


  const handleSelectValue = (e: any, name: string) => {
    setSurveillanceDetails({ ...surveillanceDetails, [name]: e.value })
  }

  return (
    <>
      <Container>
        <Card className="xcn-card mt-3">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5 className="text-secondary fw-bold">Surveillance</h5>
            <div>
              {editEnabled ? (
                <XcnButton
                  text={
                    <>
                      <FontAwesomeIcon icon={faSave} className="me-2" />
                      Save
                    </>
                  }
                  variant="success"
                  onClick={updateCenterData}
                />
              ) : (
                <XcnButton
                  text={
                    <>
                      <FontAwesomeIcon icon={faEdit} className="me-2" />
                      Edit
                    </>
                  }
                  variant="warning"
                  onClick={() => setEditEnabled(true)}
                />
              )}
            </div>
          </div>

          {/* added */}

          <Row>
            <Col md={6}>
              <InputBox
                type="number"
                name="totalNoOfSystem"
                label="Total No. of Systems under CCTV surveillance"
                defaultValue={surveillanceDetails?.totalNoOfSystem}
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </Col>
            <Col md={6}>
              <InputBox
                type="number"
                name="numberOfCamera"
                label="No. of Camera Install"
                defaultValue={surveillanceDetails?.numberOfCamera}
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </Col>
          </Row>
          <hr />
          <h6>
            Camera Details
          </h6>
          <Row className="mt-3">
            <Col md={4}>
              <Form.Group>
                <InputBox
                  type="number"
                  name="ipFHdGT2MpCamera"
                  label="Number of IP FHD (greater than or equal to 2MP)"
                  defaultValue={surveillanceDetails?.ipFHdGT2MpCamera}
                  disabled={!editEnabled}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <InputBox
                  type="number"
                  name="ipHdLT2MpCamera"
                  label="Number of IP HD (less than 2MP)"
                  defaultValue={surveillanceDetails?.ipHdLT2MpCamera}
                  disabled={!editEnabled}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group>
                <InputBox
                  type="number"
                  name="analogCam"
                  label="Number of Analog"
                  defaultValue={surveillanceDetails?.analogCam}
                  disabled={!editEnabled}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>

          </Row>
          <hr />

          <Row className="mt-3">

            <Col md={6}>
              <InputBox
                type="number"
                name="howManyDaysRecordingCanStoreInDvrHdd"
                label="How many days recording can store in DVR or HDD"
                defaultValue={surveillanceDetails?.howManyDaysRecordingCanStoreInDvrHdd}
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </Col>
            <Col md={6}>
              <InputBox
                type="text"
                name="dvrBrand"
                label="DVR/NVR Model Name"
                defaultValue={surveillanceDetails?.dvrBrand}
                disabled={!editEnabled}
                onChange={handleChange}

              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <InputBox
                type="text"
                name="perCameraRecordingSizePerHour"
                label="Per Camera Recording Size Per Hour"
                defaultValue={surveillanceDetails?.perCameraRecordingSizePerHour}
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </Col>

          </Row>


          <Row className="mt-3">
            <Col md={6}>
              <Form.Group>
                <Form.Label>DVR IP Type</Form.Label>
                <Select
                  options={enumData?.DVRCameraIP}
                  onChange={(e: any) => handleSelectValue(e, "ipType")}
                  value={[{ label: surveillanceDetails?.ipType, value: surveillanceDetails?.ipType }]}
                  isDisabled={!editEnabled}
                />
              </Form.Group>
              {
                surveillanceDetails?.ipType === "Static" &&

                <div className="mt-1">
                  <InputBox
                    type="text"
                    name="ipAddress"
                    label="Enter IP Address"
                    defaultValue={surveillanceDetails?.ipAddress}
                    disabled={!editEnabled}
                    onChange={handleChange}
                  />
                </div>
              }
            </Col>
            <Col>
              <InputBox
                type="text"
                name="backupCapacity"
                label="DVR/NVR Hard Disk Available (Capacity)"
                defaultValue={surveillanceDetails?.backupCapacity}
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </Col>

          </Row>


          {/* addedd end */}
          <Row className="mt-3">

            {/* <Col md={6}>
              <InputBox
                type="text"
                name="dvrBrand"
                label="DVR/NVR Model Name"
                defaultValue={surveillanceDetails?.dvrBrand}
                disabled={!editEnabled}
                onChange={handleChange}

              />
            </Col> */}
          </Row>
          <Row className="mt-3">
            <Col>
              <FloatingLabel
                label="How the center will give the recording on daily basis"
              >
                <Form.Control
                  defaultValue={surveillanceDetails?.howTheCenterWillGiveTheRecordingOnDailyBasis}
                  type="text"
                  as="textarea"
                  rows={5}
                  cols={5}
                  disabled={!editEnabled}
                  onChange={handleChange}
                  name="howTheCenterWillGiveTheRecordingOnDailyBasis"
                />
              </FloatingLabel>
            </Col>
          </Row>
          {/* <Row className="mt-3">
            <Col>
              <InputBox
                type="text"
                name="backupCapacity"
                label="DVR/NVR Hard Disk Available"
                defaultValue={surveillanceDetails?.backupCapacity}
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </Col>
          </Row> */}
          <div className="d-flex justify-content-start align-items-center flex-wrap gap-4 p-4">
            <span>
              <Form.Check
                inline
                type="switch"
                id="allLabCovered"
                label="Coverage of all venue (No Blind spot)"
                checked={surveillanceDetails?.allLabCovered}
                name="allLabCovered"
                disabled={!editEnabled}
                onChange={handleChange}
              // onChange={handleChange}
              // onChange={props.onChange}
              />
            </span>

            {/* added */}
            <span>
              <Form.Check
                inline
                type="switch"
                id="dvrNvrAvailability"
                label="DVR NVR Availability"
                checked={surveillanceDetails?.dvrNvrAvailability}
                name="dvrNvrAvailability"
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </span>
            <span>
              <Form.Check
                inline
                type="switch"
                id="dvrRecordingWillBeHandedOverOnDayToDayBasic"
                label="DVR recording will be handed over on day to day basis"
                checked={surveillanceDetails?.dvrRecordingWillBeHandedOverOnDayToDayBasic}
                name="dvrRecordingWillBeHandedOverOnDayToDayBasic"
                disabled={!editEnabled}
                onChange={handleChange}
              />
            </span>
          </div>
          <Row>
            <div className="d-flex justify-content-between align-items-center">
              <div className="mt-4 text-secondary fw-bold w-100">
                <h6>
                  CCTV Image
                  {/* <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-success"
                  /> */}
                </h6>
                {editEnabled === false && surveillanceDetails?.cctvPanel ? <>
                  <Image src={surveillanceDetails?.cctvPanel} height="200px" />
                </> :

                  <Row>
                    <Col md={6}>
                      <Image src={surveillanceDetails?.cctvPanel} height="200px" />
                    </Col>
                    <Col md={6}>
                      <MyUploader
                        fileSelectedHandler={(e: any) =>
                          fileSelectedHandler(e, "cctvPanel")
                        }
                      />
                    </Col>
                  </Row>}
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="mt-4 text-secondary fw-bold w-100">
                <h6>
                  DVR Image
                  {/* <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-success"
                  /> */}
                </h6>
                {editEnabled === false && surveillanceDetails?.dvr ? <>
                  <Image src={surveillanceDetails?.dvr} height="200px" />
                </> :

                  <Row>
                    <Col md={6}>
                      <Image src={surveillanceDetails?.dvr} height="200px" />
                    </Col>
                    <Col md={6}>
                      <MyUploader
                        fileSelectedHandler={(e: any) =>
                          fileSelectedHandler(e, "dvr")
                        }
                      />
                    </Col>
                  </Row>}
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="mt-4 text-secondary fw-bold w-100">
                <h6>
                  NVR Image
                  {/* <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-success"
                  /> */}
                </h6>
                {editEnabled === false && surveillanceDetails?.nvr ? <>
                  <Image src={surveillanceDetails?.nvr} height="200px" />
                </> :

                  <Row>
                    <Col md={6}>
                      <Image src={surveillanceDetails?.nvr} height="200px" />
                    </Col>
                    <Col md={6}>
                      <MyUploader
                        fileSelectedHandler={(e: any) =>
                          fileSelectedHandler(e, "nvr")
                        }
                      />
                    </Col>
                  </Row>}
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="mt-4 text-secondary fw-bold w-100">
                <h6>
                  Internet Broadband Image
                  {/* <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-success"
                  /> */}
                </h6>
                {editEnabled === false && surveillanceDetails?.internalBroadband ? <>
                  <Image src={surveillanceDetails?.internalBroadband} height="200px" />
                </> :

                  <Row>
                    <Col md={6}>
                      <Image src={surveillanceDetails?.internalBroadband} height="200px" />
                    </Col>
                    <Col md={6}>
                      <MyUploader
                        fileSelectedHandler={(e: any) =>
                          fileSelectedHandler(e, "internalBroadband")
                        }
                      />
                    </Col>
                  </Row>}
              </div>
            </div>
          </Row>
        </Card >
      </Container >
    </>
  );
}
